<template>
  <Modal v-if="isModal" v-on:dismissForm="isModal = false"></Modal>

  <h4 class="column is-12 is-size-4 is-size-12-mobile my-6">
    RASTREAR MI PEDIDO
  </h4>

  <div class="column mt-4 is-flex is-justify-content-center pb-6">
    <div
      class="column is-5-desktop is-10-mobile is-7-tablet background-gray box-shadow has-text-left h-100 p-0 mr-4"
    >
      <div class="px-5 py-4 has-text-centered">
        <span class="has-text-weight-bold">Ingresa el número de la orden</span>

        <div class="column is-12 is-relative">
          <input
            type="number"
            v-model="orderId"
            class=" pt-4 pb-1 has-text-weight-light is-size-6 input-border-red has-text-centered"
            style="background: transparent !important;"
          />
          <div class="focus-line"></div>
        </div>
      </div>
      <div
        v-if="auth.id"
        class="is-6-desktop is-12-mobile has-text-centered px-4"
      >
        <span
          @click="isModal = true"
          class="is-size-7 color-red cursor-pointer non-selectable"
          >¿No sabes tú número de orden? Búscala aquí</span
        >
      </div>
      <button
        @click="searchOrder"
        :disabled="disabled"
        id="tracking-btn"
        class="column mt-2 is-12 is-12-mobile button gradient-button-red has-text-white p-0 "
      >
        Rastrear
      </button>
    </div>
  </div>
</template>

<script src="./tracking.ts" />
